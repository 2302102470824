import React from 'react'


const AboutUs = () => {

  let center = "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]";

  return (
    <section className=' my-40 ' id='about'>
      <div className='relative flex items-center justify-center w-full py-10 ' >

      <img className="absolute top-[100px] md:top-[90%] translate-y-[-50%] w-1/4 md:w-1/6 left-0 -z-50" src={require('../../img/img_wireframe.png')} alt="imagebackgound" />
      <img className="absolute top-[300px] right-0  md:right-0 -rotate-45 translate-y-[50%] w-1/4 md:w-1/6  -z-50" src={require('../../img/img_wireframe.png')} alt="imagebackgound" />
        <div className='container flex items-center justify-center  relative' data-aos="zoom-out" data-aos-duration='1000' data-aos-delay='100'>
          <img className={`${center}`} src={require('../../img/headline.png')} alt="headline" />
          <p className="text-[#101FA3] text-[45px] font-bold ">About Us</p>
        </div>
      </div>
      <div className='container m-auto mt-0  md:mt-3 xl:mt-2'>
        <div className='w-full grid grid-cols-12 gap-4 '>
          <div className='w-full h-full col-span-12  md:col-span-7 flex items-center justify-center'>
            <p className='lg:text-start text-center text-xl lg:text-xl xl:text-3xl mx-2 md:mr-0 md:ml-[10%] mt-0 md:mt-[10%]  ' data-aos="fade-up" data-aos-duration='1000' data-aos-delay='100'>
            Within the bustling halls of <span className="special"> Shaheed Sukhdev</span>  College<span className="special"> of Business Studies</span> (SSCBS), <span className="">University of Delhi,</span> beats the digital heart <br /> of <span className="technicate">ACM SSCBS.</span> Founded in 2020 by a group of code-wielding pioneers, this student chapter has metamorphosed into a powerhouse for all things computational. ACM SSCBS champions the cause of propelling <span id="ideate">computer science</span> within the college, relentlessly advocating for its growth and development.
            But ACM SSCBS is more than just a<span className="technicate"> champion;</span> it's a playground for the tech-savvy. Here, classrooms transform into launchpads for innovation, where workshops ignite <span id="ideate"> curious minds</span> and<span id="ideate"> hackathons</span> push the boundaries of the possible. Whether you're a seasoned algorithm architect or a wide-eyed coding novice,<span className='special'> ACM SSCBS welcomes you</span> with open arms.
            </p>
          </div>
          <div className='w-full h-full col-span-12  md:col-span-5 order-[-1] md:order-1 flex items-center justify-center md:justify-start' data-aos="fade-left" data-aos-duration='1000' data-aos-delay='600'>
            <img className='w-[120%]' src={require('../../assets/designed/2.png')} alt=''/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs