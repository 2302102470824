const Speakers = [
    {
        "name": "Ms. Monika Chauhan",
        "occupation": "GM - Startups, Tech Business Incubator, CSR at DU.",
        "description": "Founder's Associate at Starfounders, Motion Design in Business",
        "img": "./data/profileImg/monika.png"
    },
    {
        "name": "Mr. Abdal Lalit",
        "occupation": "Product Analyst - BharatPe",
        "description": "",
        "img": "./data/profileImg/abdal.png"
    },
    {
        "name": "Mr. Manan Bedi",
        "occupation": "Business Analyst @ Paytm | Judge/Mentor at 10+ Hackathons",
        "description": "",
        "img": "./data/profileImg/manan.png"
    }
    
]
export default Speakers