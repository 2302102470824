import './Container.css'
import vector7 from '../../img/event1.png'
import vector8 from '../../img/event2.png'
import imageMuskan from '../../assets/designed/8.png'
import traced from '../../img/Untitled_Artwork 4 1 (Traced).svg'
import traced1 from '../../img/Untitled_Artwork 4 1 (Traced)-1.svg'
import t_asset from '../../img/T asset.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'
// import { Helmet } from 'react-helmet';

const Container = () => {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='pt-5 md:mt-8' >
            <div className='container relative mx-auto md:pt-[122.44px] md:pb-[100.3px]'>
                <div className='relative' data-aos="zoom-out" data-aos-duration='1000' data-aos-delay='100'>
                    <h1 className='text-center md:text-7xl font-[700] text-[28px]'>IdeaGem 1.0</h1>
                    <img src={traced1} alt="" className='absolute lg:top-[25px] lg:left-[-100px] lg:h-[89.61px] h-[67px] sm:top-[25px] sm:left-[-150px] top-[17px] left-[-210px]' />
                    <img src={traced} alt="" className='absolute lg:top-[-370px] lg:right-[-195px] lg:h-[415px] h-[155px] sm:top-[-120px] sm:right-[-60px] top-[-130px] right-[-120px]' />
                </div>

                <div className='flex justify-center sm:flex-wrap flex-col md:flex-row relative md:mt-20 mt-[58px] md:items-center items-center p-2 '>
                    <p className='md:w-[552px] md:text-[36px] w-fit text-[22px] font-[350] md:leading-[40.68px]' data-aos="fade-up" data-aos-duration='1000' data-aos-delay='100'>Welcome to <span className='technicate'>IdeaGem 1.0</span>, a strategic &<span id='ideate'> creative ideathon</span> inviting participants from all corners of the country to showcase their problem-solving prowess. The challenge? Develop  <span className='technicate'>innovative</span> solutions to a given theme without the need for coding, crafting prototypes in <span className='technicate'>teams</span> <br />of <span className='special'>3 to 4 individuals.</span></p>
                    <div className='image relative md:ml-16 mt-[29px]' data-aos="fade-left" data-aos-duration='1000' data-aos-delay='600' >
                        <img src={vector7} alt="" className='md:w-[448.67px] w-[307px]' />
                        {/* <img src={yellow_and_black} alt="" className='absolute md:top-[259px] md:left-[385px] md:w-[91.5px] w-[62.47px] top-[-3px] right-[-3px]'/> */}
                    </div>
                </div>
                <img src={t_asset} alt="" className='absolute md:bottom-[500px] md:right-[307px] md:w-[233px] w-[97px] right-[15.33px] bottom-[-100px] -z-40' />

                <div className='flex justify-center sm:flex-wrap flex-col md:flex-row relative md:mt-[59.5px] mt-[47px] md:items-center items-center p-2'>
                    <div className='image relative mt-8 xl:mt-0 order-2 ' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>

                        <img className='md:w-[486px] w-[336px]' src={vector8} alt="" />
                    </div>
                    <p className='md:text-[36px] md:ml-[23px] md:w-[617px] md:leading-[40.68px] w-fit text-[22px] font-[350] order-1 lg:mr-4' data-aos="fade-up" data-aos-duration='1000' data-aos-delay='100'><span id="ideate">IdeaGem 1.0</span> transcends traditional coding events, inviting students of <br /> <span className="technicate"> all coding backgrounds</span> to join. Teams, comprising both coding<span id="ideate"> experts and newcomers</span> to the tech scene, will collaborate to bring creative prototypes to life.</p>
                </div>
                <div className='flex justify-center sm:flex-wrap flex-col md:flex-row relative md:mt-[59.5px] mt-[47px] md:items-center items-center p-2'>
                    <div className='image relative mt-8 xl:mt-0 order-2 ' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>

                        <img className='md:w-[486px] w-[336px]' src={imageMuskan} alt="" />
                    </div>
                    <p className='md:text-[36px] md:ml-[23px] md:w-[617px] md:leading-[40.68px] w-fit text-[22px] font-[350] order-1 lg:mr-4' data-aos="fade-up" data-aos-duration='1000' data-aos-delay='100'> The unique aspect of this event lies in <span className="special"> "CBSCoins,"</span> empowering teams to make<span id="ideate"> strategic decisions,</span> such as <span className="technicate">changing themes or tech stacks,</span> or gaining <span className="technicate">extra time.</span> It's a competition that goes beyond coding norms, fostering  <br />diverse <span className="special">thinking and innovation.</span> Join us to redefine possibilities and embark on this exciting journey together!</p>
                </div>
                <img src={t_asset} alt="" className='absolute md:bottom-[-65px] md:right-[137px] md:w-[233px] w-[97px] right-[15.33px] bottom-[-100px] -z-40' />
            </div>
        </div>
    )
}

export default Container