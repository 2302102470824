import React from 'react'
import CountUp from 'react-countup';
import Confetti from 'react-confetti'


const Stats = () => {
  return (
    <section className=' relative h-screen py-10 w-full flex justify-center items-center flex-col' id='stats'>
        <Confetti className='absolute top-0 right-0 ' numberOfPieces={120} gravity={0.1}/>
        <p className="text-black text-[60px] font-bold ">IdeaGem 1.0 - Stats</p>
            <div className='grid grid-cols-2 gap-4 w-full h-[80vh] mt-10'>
                    <div className=' flex items-center justify-center flex-col border w-1/5 h-fit p-20 px-64 m-auto rounded-3xl' style={{"boxShadow": "15px 15px 0px black"}}>
                        <h1 className='text-[#101FA3] text-7xl font-bold'><CountUp redraw={2} delay={1} start={200} end={500} />+</h1>
                        <p className='text-black text-3xl font-bold '>Registrations</p>
                        
                    </div>
                    <div className='flex items-center justify-center flex-col border w-1/5 h-fit p-20 px-64 m-auto rounded-3xl' style={{"boxShadow": "15px 15px 0px black"}}>
                        <h1 className='text-[#101FA3] text-7xl font-bold'><CountUp delay={1} start={10} end={30} />+</h1>
                        <p className='text-black text-3xl font-bold'>Project Ideas</p>
                    </div>
                    <div className='flex items-center justify-center flex-col border w-1/5 h-fit p-20 px-64 m-auto  rounded-3xl' style={{"boxShadow": "15px 15px 0px black"}}>
                        <h1 className='text-[#101FA3] text-7xl font-bold'><CountUp delay={1} start={30000} end={53000} />+</h1>
                        <p className='text-black text-3xl font-bold w-80'>IdeaGem Website Visitors</p>
                    </div>
                    <div className='flex items-center justify-center flex-col border w-1/5 h-fit p-20 px-64 m-auto rounded-3xl' style={{"boxShadow": "15px 15px 0px black"}}>
                        <h1 className='text-[#101FA3] text-7xl font-bold'><CountUp delay={1} start={2} end={10} />+</h1>
                        <p className='text-black text-3xl font-bold'>Sponsors</p>
                    </div>
            </div>
    </section>
  )
}

export default Stats