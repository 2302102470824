import './Sponsors.css'
import { Link } from 'react-router-dom';

const Sponsors = () =>  {
  let center = "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]";
  return(
      <div className='relative font-whyte mb-10' id='sponsors'>
        <img class="absolute top-0 translate-y-[-5.5rem] w-full" src={require('../../img/line.png')} alt="lined"/>
        <div className='w-full mt-8 flex items-center justify-center flex-col'>
          <div className='container flex items-center justify-center  relative' data-aos="zoom-out" data-aos-duration='1000' data-aos-delay='100'>
            <img className={`${center}`} src={require('../../img/headline.png')} alt="headline"/>
            <p className="text-[#101FA3] text-[45px] font-bold ">Sponsors</p>
          </div>
          <div class="grid sm:grid-cols-3 grid-cols-1 gap-[2rem] gap-x-4 w-full items-center justify-items-center justify-center  py-[3rem]">
            <div className=' bg-transparent relative animation-speakers' data-aos="fade-left" data-aos-duration='1000' data-aos-delay='600'>
              <Link to='https://devfolio.co/' target='_blank'>
                <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[80%] lg:w-[80%] xl:w-[80%]" src={require('../../img/Devfolio_Logo-Colored.png')} alt="DEVFOLIO LOGO"/>
              </Link>
            </div>
            <div className=' bg-transparent relative animation-speakers' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>
              <Link to='https://polygon.technology/' target='_blank'>
                <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[70%] lg:w-[70%] xl:w-[70%]" src={require('../../img/Polygon_Logo-Colored.png')} alt="POLYGON LOGO"/>
              </Link>
            </div>
            <div className=' bg-transparent relative animation-speakers' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>
              <Link to='https://ethindia.co' target='_blank'>
                <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[80%] lg:w-[80%] xl:w-[80%]" src={require('../../img/ethindia-dark.png')} alt="ETHINDIA LOGO"/>
              </Link>
            </div>
            <div className=' bg-transparent relative animation-speakers' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>
              <Link to='https://gen.xyz/' target='_blank'>
                <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[70%] lg:w-[60%] xl:w-[60%]" src={require('../../img/xyz-logo-color.png')} alt="XYZ Domain Logo"/>
              </Link>
            </div>
            <div className=' bg-transparent relative animation-speakers' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>
              <Link to='https://replit.com/' target='_blank'>
                <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[70%] lg:w-[60%] xl:w-[60%]" src={require('../../img/replit-dark.png')} alt="REPLIT LOGO"/>
              </Link>
            </div>
            <div className=' bg-transparent relative animation-speakers' data-aos="fade-right" data-aos-duration='1000' data-aos-delay='600'>
              <Link to='https://rosenfeldmedia.com/' target='_blank'>
                <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[80%] lg:w-[80%] xl:w-[80%]" src={require('../../assets/sponsors/RM-Logo_20-percent-768x257.webp')} alt="REPLIT LOGO"/>
              </Link>
            </div>
            <div className='w-full flex items-center justify-center col-span-full'>
              <div className=' bg-transparent relative animation-speakers self-center' data-aos="fade-left" data-aos-duration='1000' data-aos-delay='600'>
                <Link to='https://www.codingninjas.com/'>
                  <img className="" src={require('../../img/headlineb.png')} alt="imagebackgound"/>
                  {/* <img className={`${center} -z-10`} src={yellowzigzag} alt="imagebackgound"/> */}
                  <img className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] md:w-[80%] lg:w-[80%] xl:w-[80%]" src={require('../../img/CN_Grey_RGB.png')} alt="XYZ Domain Logo"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Add Button To Connect With Us For Sponsorship */}
        <div className='flex items-center justify-evenly py-5' >
        <a className='bg-[#1b1b1b] button md:px-12 md:py-2  md:text-[22px] text-[15px] px-5 py-1 text-white rounded-[7px] animate-bounce  hover:bg-gray-300 hover:text-black transition-all duration-300'
           target="_blank" href='mailto:acm.sscbs@gmail.com' rel="noopener noreferrer">Sponsor Us</a>
        <a className='bg-[#1b1b1b] button md:px-12 md:py-2  md:text-[22px] text-[15px] px-5 py-1 text-white rounded-[7px] animate-bounce hover:bg-gray-300 hover:text-black transition-all duration-300'
           target="_blank" href='https://forms.gle/qYR9125EGa2PAXYz7' rel="noopener noreferrer">Apply as Community Partner</a>
        <a className='bg-[#1b1b1b] button md:px-12 md:py-2  md:text-[22px] text-[15px] px-5 py-1 text-white rounded-[7px] animate-bounce  hover:bg-gray-300 hover:text-black transition-all duration-300'
           target="_blank" href='https://forms.gle/FsRAYG1cRacSFStV7' rel="noopener noreferrer">Apply as Mentor</a>
        </div>
        
      </div>
    )
  }

export default Sponsors 