import React from 'react';
import './App.css';
import AboutUs from './components/aboutUs/AboutUs';
import Navbar from './components/navbar/Navbar';
import Content from './components/timer/Content';
import AboutEvent from './components/about-event/Container';
import Rules from './components/rules/Rules';
import Sponsors from './components/sponsors/Sponsors';
import Footer from './components/footer/Footer';
import Agenda from './components/agenda/Agenda';
import SpeakersSection from './components/speakers/Speakers';
import { BrowserRouter } from 'react-router-dom';
import 'aos/dist/aos.css';
import Stats from './components/stats/Stats';



function App() {

  return (
    <div className="App overflow-hidden scroll-smooth">
      <BrowserRouter>
      <Navbar />
      <Content />
      <Stats />
      <AboutEvent />
      <AboutUs />
      <SpeakersSection />
      <Rules />
      <Agenda />
      <Sponsors />
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
