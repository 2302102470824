import React from 'react'


const Rules = () => {

  let center = "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]";

  const rulesPdfUrl = '/Rules.pdf';

  return (
    <section className=' my-10 pb-40' id='about'>
      <div className='relative flex items-center justify-center w-full py-10 ' >
        <div className='container flex items-center justify-center  relative' data-aos="zoom-out" data-aos-duration='1000' data-aos-delay='100'>
          <img className={`${center}`} src={require('../../img/headline.png')} alt="headline" />
          <p className="text-[#101FA3] text-[45px] font-bold ">Rules</p>
        </div>
      </div>
      <div className='container m-auto mt-0  md:mt-3 xl:mt-2'>
        <div className='w-full grid grid-cols-12 gap-4 '>
          <div className='w-full h-full col-span-12  md:col-span-7 flex items-center justify-center'>
            <p className='lg:text-start text-center text-xl lg:text-xl xl:text-3xl mx-2 md:mr-0 md:ml-[10%] mt-0 md:mt-[10%]  ' data-aos="fade-up" data-aos-duration='1000' data-aos-delay='100'>
            Here you can find the <span className='technicate'>rules and regulations</span> that govern our activities and events. Please review them carefully to ensure a smooth and enjoyable experience for everyone involved.
            <a href={rulesPdfUrl} target='_blank' rel='noopener noreferrer' className=' hover:underline ml-10' style={{ color: '#88A6F6' }}><span className='special'>View Rules (PDF)</span></a>
            </p>
            </div>
            <div className='w-full h-full col-span-12  md:col-span-5 flex items-center justify-center'>
            <p className='lg:text-start text-center text-xl lg:text-xl xl:text-3xl mx-2 md:mr-0 md:ml-[10%] mt-0 md:mt-[10%]  ' data-aos="fade-up" data-aos-duration='1000' data-aos-delay='100'>
            Here you can find the <span className='technicate'>Presentation Structure</span>
            <a href={"/Presentation.pptx"} target='_blank' rel='noopener noreferrer' className=' hover:underline ml-10' style={{ color: '#88A6F6' }}><span className='special'>Presentation (PPTX)</span></a>
            </p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Rules